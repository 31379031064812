export const ADD_PRODUCT_START = 'ADD_PRODUCT_START'
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR'

export const GET_PRODUCT_START = 'GET_PRODUCT_START'
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'

export const DELETE_PRODUCT_START = 'DELETE_PRODUCT_START'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR'

export const UPDATE_PRODUCT_START = 'UPDATE_PRODUCT_START'
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS'
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR'