export const ADD_CATEGORY_START = 'ADD_CATEGORY_START'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR'

export const GET_CATEGORY_START = 'GET_CATEGORY_START'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR'

export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'

export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'