export const ADD_CART_START = 'ADD_CART_START'
export const ADD_CART_SUCCESS = 'ADD_CART_SUCCESS'
export const ADD_CART_ERROR = 'ADD_CART_ERROR'

export const GET_CART_START = 'GET_CART_START'
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
export const GET_CART_ERROR = 'GET_CART_ERROR'

export const DELETE_CART_START = 'DELETE_CART_START'
export const DELETE_CART_SUCCESS = 'DELETE_CART_SUCCESS'
export const DELETE_CART_ERROR = 'DELETE_CART_ERROR'

export const UPDATE_CART_START = 'UPDATE_CART_START'
export const UPDATE_CART_SUCCESS = 'UPDATE_CART_SUCCESS'
export const UPDATE_CART_ERROR = 'UPDATE_CART_ERROR'

 
 